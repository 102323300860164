import { Injectable, ElementRef } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AnimationStateService {
  animationController:boolean = true;
  previousAnimationStart: number = 0;
  currentAnimationStart:number =0;
  animationDurationIndicator:number = 0;
  animationStart:number = 0;
  animationshowStop:boolean = false;
  buildUpFinished:boolean = false;
  playPauseToggle!: ElementRef;
  imgSkipNext!: ElementRef;
  imgSkipPrevious!: ElementRef;
  animationControllerPanel!: ElementRef;
  mq_1496 = window.matchMedia("(min-width: 1496px)");
  mq_1368 = window.matchMedia("(min-width: 1368px)");
  mq_1246 = window.matchMedia("(min-width: 1246px)");
  mq_1098 = window.matchMedia("(min-width: 1098px)");


  //--------------------------------------------------------------------------------
  setPlayPauseToggle(playPauseToggle: ElementRef) {
    this.playPauseToggle = playPauseToggle;
  }
  setImgSkipNext(imgSkipNext: ElementRef) {
    this.imgSkipNext = imgSkipNext;
  }
  setImgSkipPrevious(imgSkipPrevious: ElementRef) {
    this.imgSkipPrevious = imgSkipPrevious;
  }
  setAnimationControllerPanel(animationControllerPanel: ElementRef) {
    this.animationControllerPanel = animationControllerPanel;
  }
  // getPlayPauseToggle(): ElementRef | undefined {
  //   return this.playPauseToggle;
  // }
}