import { Component, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { logInUser } from './store/app.actions'

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})


export class AppComponent {
  title = 'product-site';
  @Input() public isUserLoggedIn!: boolean;
  constructor(
    private store: Store<{Auth: {loggedIn: boolean} }>
  
  ) { }


  ngOnInit(): void {
    const isLoggedIn = localStorage.getItem('isLoggedIn');
    if (isLoggedIn === 'true') {
      this.store.dispatch(logInUser())
    }

    this.store.select('Auth').subscribe(data => {
      this.isUserLoggedIn = data.loggedIn
    })

  }


}

