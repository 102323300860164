<div #showcase class="showcase SC text-center" style="overflow: hidden; height: 100%; opacity: 0;">
  <div class="showcase-wrapper">
    <h2 class="showcase-heading"></h2>
    <div class="app-wrapper" style="display: none;">
      <img class="showcase-laptop" src="../assets/img/laptop.svg" alt="" style="">
      <video #ShowVideo id="showcaseVideo" class="show-video" src="../assets/videos/AppVideoFinal.mp4"  width="100%" height="100%" style="opacity: 0;"></video>
    </div>
    <!-- <div class="showCaseLoop" style="position: absolute; left: 50%; top: 50%; width: 98%; overflow: hidden;height: 100%; scale: 1.1;transform: translate(-43%, -47%);scale: 1.15;transform: translate(-38%, -50%);">
      <video #ShowVideo id="showcaseVideoLoop" class="show-video" src="../../assets/videos/Laptop-Loop.mp4"  width="100%" height="100%" loop autoplay style="scale:1.02;"></video>
    </div> -->
  </div>
  
  <div>
      <div class="panel6-formular" (click)="showForm()" style="z-index: 10;padding: 1rem;border-radius: 1rem; position: absolute; left: 50%; bottom: 0; transform: translateX(-50%); display: none; background-color: #009D15;">
        <h3 style="cursor:pointer;color: #FFF;font-size: 1.4rem;padding: 0.4rem;padding-top: 0rem;padding-bottom: 0rem;">Jetzt mit einem Experten sprechen & <br><span style="text-decoration: underline;">Starterbonus sichern</span></h3>
      </div>
      <div class="restart-tour" (click)="restartTour()" style="color: #BDBDBD;position: absolute; bottom: 0; left: 50%; display: none;">
          <a style="cursor:pointer; font-size: 1.2rem;">Tour wiederholen</a>
      </div>
      <div class="partners" style="position: absolute;left: 50%;transform: translateX(-50%);bottom: 54px; display: none;">
        <p style="margin-left: -50px;margin-bottom: -25px; text-align:left;color: #9a9a9aad;">Unsere Partner/ Investoren</p>
        <img style="scale: .8;" src="../../assets/img/PartnerLogos.png" alt="">
      </div>
  </div>
  <app-tour-guid [_start_tour]="_start_tour" [restart]="restart" [_showForm]="_showForm" [_closeForm]="_closeForm" style="display:none;position: absolute; top: 50%;left: 50%;transform: translate(-50%, -59%);width: 700px;"></app-tour-guid>
  <app-whiteboard class="whiteboard-contaienr" style="height: 50%;overflow: hidden;position: absolute;left: 50%;top: 3rem;z-index: -1;transform: translateX(-50%);width: 99vw;max-width: 1400px;"></app-whiteboard>
  <app-student style="position: absolute; top: 50%; right: 50%;transform: translate(-50%, 50%);"></app-student>
  <app-rent-vr style="position: absolute; left: 50%; top: 50%;transform: translate(-50%, -50%)"></app-rent-vr>
  <app-vr-dome style="position: absolute; top: 0; left: 50%; width: 64vw;transform: translateX(-50%)"></app-vr-dome>
  <app-vr-goggle style="position: absolute; left: 50%; top: 50%;transform: translate(-50%, -50%)"></app-vr-goggle>
  <app-conclusio style="position: absolute;"></app-conclusio>

  <app-contact-form class="contact-form" style="padding-top: 1rem;background-color: transparent;position:absolute; left:50%; top:7rem; transform:translateX(-50%); z-index: 100;"></app-contact-form>
  <!-- <a class="restartTour" style="cursor: pointer;display: none;justify-content: center;text-decoration: none;">
    <h5 mat-dialog-title class="mat-dialog-title" style="user-select: none;border-radius: .25rem;padding: 1rem; padding-left: 2rem; padding-right: 2rem; background-color: #021824; color: #fff; margin-top: 2rem;">Tour wiederholen</h5>
  </a>   -->

  <div class="conc-laptop" style="position:absolute; top: 50%; left:50%; height: 24rem; display: none; transform: translate(-50%, -50%);">
    <img src="../../assets/img/Products.png" alt="" width="100%" height="100%">
  </div>

  <div class="start-tour" style="margin-top: 2rem; display: none;">
    <h2 style="color:#464646">Lassen Sie uns jetzt gemeinsam einen Blick in die App werfen</h2>
  </div>

  <div class="panel1" style="position:absolute; top: 2rem; left:50%; display: none;">
    <h2 class="panel-header"  style="color: #00519D; text-align: left;">Das richtige Anschauungsmaterial</h2>
    <div class="panel-content" style="text-align: left;margin-left: 4.4rem;width: 32rem;">
      <h3 class="panel-content-subhead" style="font-weight: 300;color: #454545; margin-bottom: 4rem; display: none;">für jede Unterrichtssituation</h3>
      <h4 class="panel-content-text" style="font-weight: 300;color: #7A7A7A; display: none;">Über <b style="color:black">270 Filme</b> aus allen Kategorien des theoretischen Unterrichts</h4>
      <h4 class="panel-content-text2" style="font-weight: 500;color: #7A7A7A; display: none;">Übertragen Sie <b style="color: #000;">zusätzlich</b> Inhalte der <b style="color: #000;">Fahrschule360 VR-Brille</b> auf Ihren Schulungs-Screen.</h4>
    </div>
  </div>

  <div class="panel2" style="position:absolute; top: 2rem; left:16rem; display: none; opacity: 0;">
    <h2 class="panel2-header"  style="color: #00519D; text-align: left;">Besprechen Sie die <span style="color:#C67438">Blickführung</span> Ihrer Schüler</h2>
    <div class="panel2-content" style="text-align: left;margin-left: 4.4rem;width: 32rem;">
      <h3 class="panel2-content-subhead" style="font-weight: 300;color: #454545; margin-bottom: 5rem; display: none;">direkt im theoretischen Unterricht.</h3>
      <h4 class="panel2-content-text" style="font-size:1.8rem;font-weight: 300;color: #7A7A7A; display: none; width: 378px;">Übertragen Sie Inhalte der <b style="color:black">Fahrschule360 VR-Brille</b> auf Ihren Schulungs-Screen.</h4>
    </div>
  </div>

  <div class="panel3" style="position:absolute; top: 7rem; left:50%; display: none; height: 68%; transform: translate(-50%, 0px); width: 77%;">
    <h2 class="panel3-header"  style="color: #00519D; text-align: left;">Bringen Sie außerdem <span style="color:#C67438">zusätzliche Erlöse</span> in Ihre Fahrschule...</h2>
    <div class="panel3-content" style="text-align: left;">
      <div style="display: flex;">
        <h3 class="panel3-content-subhead" style="margin-right: .5rem;font-weight: 300;color: #00519D; margin-top: 2rem;margin-bottom: 8rem; display: none;">Wie?</h3>
        <h3 class="panel3-content-subhead2" style="font-weight: 300;color: #00519D; margin-top: 2rem;margin-bottom: 8rem; display: none;">Mit <span style="color:#C67438">VR-Fahrstunden</span> für Ihre Fahrschüler</h3>
      </div>
      <h4 class="panel3-content-text" style="margin-left: 5rem;font-weight: 300;color: #7A7A7A; display: none;"></h4>
      <div style="position: absolute;right: 3rem;bottom: 0rem;">
        <p class="panel3-content-subtext" style="color: #888A8A;display: none;font-size: 1.2rem;">Kein teures Benzin, Kein Verschleiß. Just VR.</p>
      </div>
    </div>
  </div>

  <div class="panel4" style="position:absolute; top: 2rem; left:16rem; display: none;">
    <h2 class="panel4-header"  style="color: #00519D; text-align: left;">Lassen Sie Ihre Fahrschüler <span style="color:#C67438">VR-Fahrstunden</span> buchen</h2>
    <div class="panel4-content" style="text-align: left;">
      <h3 class="panel4-content-subhead" style="font-weight: 300;color: #464646; margin-left: 6rem;margin-bottom: 2rem; display: none;background: white; padding-top: 1rem;padding-bottom: 1rem;padding-right: 1rem;">Ihr Potential: Deutlich mehr Erlöse als Kosten für unser System!</h3>
      <h4 class="panel4-content-text" style="margin-left: 5rem;font-weight: 300;color: #7A7A7A; display: none;"></h4>
      <div class="panel4-merger">
        <p class="panel4-content-subtext" style="margin-bottom: 1rem;color: #000;display: none;font-size: 1.2rem;font-weight: 500;">Die Fahrschule360 VR-Brille trainiert</p>
        <p class="panel4-content-subtext2" style="margin-bottom: .2rem;color: #888A8A;display: none;font-size: 2rem;color:#C67438">Blickschulung</p>
        <p class="panel4-content-subtext3" style="margin-bottom: .2rem;color: #888A8A;display: none;font-size: 2rem;margin-left: 4rem;color:#C67438">Gefahrenwahrnehmung</p>
        <p class="panel4-content-subtext4" style="margin-bottom: .2rem;color: #888A8A;display: none;font-size: 2rem;margin-left: 8rem;color:#C67438">Aufmerksamkeit</p>
      </div>

      <div class="panel4-emerge" style="">
        <p style="font-size: 1.5rem; color: #888A8A; width: 32rem;">Bereitet optimal auf den realen Straßenverkehr und die praktische Prüfung vor</p>
      </div>
    </div>
  </div>


  <div class="panel5" style="position:absolute; top: 2rem; left:16rem; display: none;">
    <h2 class="panel5-header"  style="color: #00519D; text-align: left;">Das Fahrschule360 Lernprogramm</h2>
    <div class="panel5-content" style="text-align: left;">
      <h3 class="panel5-content-subhead" style="margin-left:6rem;font-weight: 300;color: #464646; margin-top: 2rem;margin-bottom: 8rem; display: none;">Eigenständig & spielerisch lernen</h3>
      <h4 class="panel5-content-text" style="margin-left: 5rem;font-weight: 300;color: #7A7A7A; display: none;"></h4>
      
    </div>
  </div>
  
  <!-- <div>
    <p class="panel5-content-subtext" style="color: #888A8A;display: none;font-size: 1.2rem; width: 100%;">Lange Übungsfahrten mit verschiedensten Verkehrssituationen</p>
  </div> -->



  <div class="panel6" style="">
      <h3 class="panel6-usp1" style="display:none;font-weight: 400;"><span style="color:#00519D">Reale Verkehrssituationen</span> in 360° erlebbar machen</h3>
      <h3 class="panel6-usp2" style="display:none;font-weight: 400;margin-top: 2rem; margin-bottom: 2rem;">Das richtige Anschauungsmaterial für <span style="color:#00519D">jede Unterrichtssituation</span> </h3>
      <h3 class="panel6-usp3" style="display:none;font-weight: 400;"><span style="color:#00519D">Reale Fahrstunden</span> für Ihre Fahrschüler</h3>

  </div>

  <div class="panel6-logo" style="background-color: white;position: absolute; left: 0; top:0; display: none;">
    <div style="display: flex;align-items: center;">
      <img src="../../assets/img/logo.svg" alt="" style="width: 7rem;">
      <h3 class="panel6-logo-s1" style="display: none; color: #70706E;margin-left: 4rem;">Wahrnehmen.</h3>
      <h3 class="panel6-logo-s2" style="display: none;color: #70706E;margin-left: .5rem;">Verstehen.</h3>
      <h3 class="panel6-logo-s3" style="display: none;color: #70706E;margin-left: .5rem;">Trainieren.</h3>

    </div>
  </div>



  <div class="close-formular"(click)="closeForm()" style="padding: 0.5rem; border-radius: 1.5rem;position: absolute; left: 100%; top: 2rem; display: none;">
    <h2 style="cursor:pointer">X</h2>
  </div>


  <div class="logout-wrapper">
    <p class="logout" (click)="logoutUser()" style="display: flex;">Logout <i class="material-icons" style="margin-left: .5rem;">exit_to_app</i></p>
  </div>

  <div class="info-boxes ib-connection" style="right:0; bottom:-110px; top: auto; width: 42rem;">
    <li style="list-style: none;">Übertragen Sie den Blick Ihrer Schüler auf den großen Bildschirm, um Wahrnehmung zu diskutieren.</li>
  </div>
  <div class="info-boxes ib-rent" style="right:-400px; top:50%">
    <li style="list-style: none;">Verleihen Sie die Brille, um</li>
  </div>
  <div class="info-boxes ib-goggle" style="right:-400px;">
    <li style="list-style: none;">Lassen Sie Ihre Schüler selbstständig in der Brille lernen.</li>
  </div>
  <div class="info-boxes ib-grid" style="right:0; bottom:-160px; top: auto; width: 42rem;">
    <li style="list-style: none;">Unser Trainingsprogramm routiniert das korrekte Blicken im Straßenverkehr, testet Gefahrenwahrnehmung und motiviert mit Statistiken und Highscores. Ebenfalls geeignet für Schüler mit Berührungsängsten im echten Verkehr.</li>
  </div>

  <div class="info-boxes ib-conclusio" style="right: -400px; display: none;">
    <li style="list-style: none;">200+ Verkehrssituationen zur Verwendung im theoretischen Unterricht</li>
    <li style="list-style: none;">12 volle Trainingsfahrten in Innenstadt, Landstraße und Autobahn auf der VR-Brille</li>
    <li style="list-style: none;">Brillenverleih</li>
  </div>

  <div #animationControllerPanel *ngIf="getAnimationController()"  class="animation-controller">
    <button mat-button (click)="showPrevious()" class="mat-button-sm" style="padding-right: 0px; margin-right: -15px;">
      <i #imgSkipPrevious class="material-icons controller-el" style="font-size: 2rem;display: none; ">skip_previous</i>
    </button>
    <button mat-button (click)="showStop()" class="mat-button-sm" style="padding-left: 0px; padding-right: 0px;">
      <div style="display: flex; justify-content: center; align-items: center;">
        <i #playPauseToggle class="material-icons controller-el" style="margin: 0.5rem;font-size: 2.5rem;z-index: 10;">
          pause_circle
        </i>
        <circle-progress id="circleProgress"
          *ngIf="!getAnimationShowProgress()"
          [percent]="getAnimationDurationIndicator()"
          [radius]="20"
          [showTitle]="false"
          [showSubtitle]="false"
          [showUnits]="false"
          [outerStrokeWidth]="3"
          [innerStrokeWidth]="1"
          [outerStrokeColor]="'#d6d6d6'"
          [innerStrokeColor]="'#C7E596'"
          [animation]="true"
          [animationDuration]="0"
          style="position: absolute; z-index: 5; opacity: 0;"
        ></circle-progress>
      </div>


    </button>
    <button mat-button (click)="showNext()" class="mat-button-sm" style="padding-left: 0;margin-left: -15px;">
      <i #imgSkipNext class="material-icons controller-el" style="font-size: 2rem;display: none;">skip_next</i>
    </button>
  </div>

</div>