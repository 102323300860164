<!-- <div id="goggle" style="height:40rem;margin-top:10rem;width: 100vw; display: flex; justify-content: center; position: relative;"> -->
  <!-- <div class="col-6" style="height: 500px; background-color: antiquewhite; display: flex; justify-content: center;">
    <img src="../../assets/img/pico.svg" alt="" width="80%">
  </div>


  <div class="col-3" style="height: 500px; background-color: aquamarine;"></div> -->
  <!-- <div class="sketchfab-embed-wrapper"style="width:100%"> <iframe title="Pico G2 VR Headset" width="100%" height="100%"  frameborder="0" allowfullscreen mozallowfullscreen="true" webkitallowfullscreen="true" allow="autoplay; fullscreen; xr-spatial-tracking" xr-spatial-tracking execution-while-out-of-viewport execution-while-not-rendered web-share src="https://sketchfab.com/models/2dc84075a42248afa377ebaf801ff601/embed?autostart=1&preload=1&dnt=1"> </iframe> </div> -->
<!-- </div> -->
<div #goggle class="goggle" style="height: 350px; display: none; z-index: 10;">
  <img class="front-vr" src="../../assets/img/VR_Brille+Shadow.png" alt="" width="100%" height="100%">
  <img class="conclusio-vr" src="../../assets/img/VR_Brille+Shadow.png" alt="" width="100%" height="100%" style="display: none;">
</div>