import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { gsap } from "gsap";
@Component({
  selector: 'app-intro',
  templateUrl: './intro.component.html',
  styleUrls: ['./intro.component.css']
})
export class IntroComponent implements OnInit {
  @ViewChild('video1') video1!: ElementRef;
  @ViewChild('IntroVideo') IntroVideo!: ElementRef;
  @ViewChild('Intro') Intro!: ElementRef;
  constructor() { }

  ngOnInit(): void {

  }



  public playIntroVideo() {
    this.IntroVideo.nativeElement.play()
    let tl = gsap.timeline()
    tl.to(".video-controls", {opacity:0, display:'none', duration: .6})
    tl.to(".header-formular", {opacity:1})

  }

  public closeIntroComponent(){
    // close teaser video
    this.IntroVideo.nativeElement.pause()
    let tl = gsap.timeline()
      tl.to(".IntroComponent", {x: -1000, ease: "power1.Out", opacity:0,display:"none", duration: .4, onComplete: this.closeIntroEL})
      tl.fromTo(".showcase", {x: 1000, opacity:0, scale:1}, {x:0,opacity: 1, ease: "power1.inOut", duration: .4, delay:0})
      tl.to(".app-wrapper", {opacity:0, display:'flex'})
      
    // tl.to(".topic", {innerText: "1. Nutze die App  im Unterricht", opacity:0, duration: 0, delay: -.1});
    // tl.to(".topic", {innerText: "1. Nutze die App  im Unterricht", opacity:1, duration: .4, delay: .1});


  }

  closeIntroEL(){
    // this.Intro.nativeElement.style.display='none'
  }


}
