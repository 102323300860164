import { HostListener, Component, OnInit, ViewChild, Input, ElementRef } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import * as toastr from 'toastr';
import axios from 'axios';
import { NgForm } from '@angular/forms';
import { environment } from '../../environments/environment';


@Component({
  selector: 'app-contact-form',
  templateUrl: './contact-form.component.html',
  styleUrls: ['./contact-form.component.css']
})
export class ContactFormComponent implements OnInit {
  email=''
  name=''
  _callToggle=''
  telephone=''
  date=''
  time=''
  message=''
  isFormValid: boolean = false;
  @ViewChild('formRef') form?: NgForm;
  @ViewChild('logout') logout?: ElementRef;
  callToggle = true;
  constructor(private http: HttpClient, private el: ElementRef) { }



  @HostListener('change') onChange() {
    const inputValue = this.logout?.nativeElement.value;
    console.log(this.logout?.nativeElement.value)
    const selectedDateTimestamp = Date.parse(inputValue);


    if (isNaN(selectedDateTimestamp)) {
      // alert("Invalid date format. Please enter a valid date.");
      this.el.nativeElement.value = '';
      return;
    }

    const selectedDate = new Date(selectedDateTimestamp);
    const now = new Date();
    if (selectedDate < now) {
      // alert("Please select a date in the future.");
      this.el.nativeElement.value = '';
    }
  }
  ngOnInit(): void {
    toastr.options.positionClass = 'toast-top-right';
    toastr.options.closeButton = true;
    
  }


  onSubmit() {
    // Prepare the form data
    let formData = {
      email: this.email,
      name: this.name,
      _callToggle: this.callToggle,
      telephone: this.telephone,
      date: this.date,
      time: this.time,
      message: this.message
    };

    if (this.name && this.email){
      this.isFormValid = true
    }


    if (this.isFormValid) {
      // Send the email
      console.log('form valid')
      this.isFormValid = false
      // Send the POST request to the server
      axios.post(environment.FORM_URL, formData)
      .then(response => {
        console.log('Form sent successfully', response);
        toastr.success('Form sent successfully');
      })
      .catch(error => {
        console.log('Error', error);
        toastr.error('Error sending the form');
      });

    } else {
      toastr.error('Form not valid');
      this.isFormValid = false
    }


  } 
}
