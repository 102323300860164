import { Renderer2 } from "@angular/core";
import { gsap } from "gsap";
import { AnimationStateService } from '../../services/animationState.service';

//--------------------------------------------------------------------------------
// Panel Builder
//--------------------------------------------------------------------------------
export function showApp(
                          tl: gsap.core.Timeline,  
                          animationStateService: AnimationStateService,
                          renderer:Renderer2
                        ) {
  tl
      .call(() => {
        animationStateService.currentAnimationStart = tl.time()
        animationStateService.previousAnimationStart = animationStateService.currentAnimationStart
      })   
      .to(".app-wrapper", {display:'flex'})
      .fromTo(".start-tour", {opacity:0, display:"none"}, {opacity: 1,ease: "power1.inOut", display:"block",duration: .4, delay:0})
  
      //show tour start panel
      .to(".whiteboard", {x: 1000, opacity:0, delay:1})
      // .to(".whiteboard-video", {display: "block", opacity:0})
      .fromTo(".show-video",{opacity:0}, {opacity: 1, ease: "power2.inOut", duration: .4, delay:0})
      .fromTo(".showcase-wrapper", {x:"-50%",y:"2rem",scale:.9, opacity:0, display:"none"}, {x:"-50%",y:"0rem",scale:1, opacity:1, display:"flex",duration: .8, delay:-.4})
      .to(".tour-wrapper", {display:'flex', scale:1.01, duration:.6, onComplete: () => {
        gsap.set(".show-video", {className: "show-video-active"})
        startVideo(renderer)
      }})

      // app Heading
      .to(".start-tour", {y:"-2rem",opacity:0,display: "none", delay:3.5, duration:-1.4})
      .to(".showcase-wrapper",{scale:1.3,duration: .8, delay:1.5})

      // wait for app intro to finish
      // .set({}, {}, "+=1")
      .set({}, {}, "+=40")
}

export function buildPanel1(
                              tl: gsap.core.Timeline, 
                              animationStateService: AnimationStateService
                            ) {
  
  tl
    .call(() => {
      animationStateService.previousAnimationStart = animationStateService.currentAnimationStart
      animationStateService.currentAnimationStart = tl.time()
      animationStateService.animationControllerPanel.nativeElement.style.opacity=1
    })    
    .to(".showcase-wrapper", {scale:.5,x:"-76%", y:"15%", onComplete: () => {
      gsap.set(".front-vr", {className: "front-vr-hidden"})
      gsap.set(".conclusio-vr", {className: "conclusio-vr-active"})
    }})
    .fromTo(".panel1", {x: "-100%",  opacity:0, display:"block"}, {x:"-83%", opacity:1,ease: "power2.inOut", duration: .8, delay:-.3}) 
    .fromTo(".whiteboard", {x:"49rem", y:"4rem", scale:1.7, opacity:0, display:"block"}, {x:"87%", y:"4rem",scale:2, opacity:1,ease: "power2.Out", duration: .8, delay:-.7})   
    .fromTo(".panel-content-subhead", {x: "-10%",  opacity:0, display:"block"}, {x:0, opacity:1,ease: "power2.inOut", duration: .8, delay:-.3})           
    .to(".panel-content-text", {x:"-100%", opacity:0,ease: "power2.Out", duration: .8, delay:1, display:"none"})       
    .fromTo(".panel-content-text2", {x: "20%",  opacity:0, display:"block"}, {x:0, opacity:1,ease: "power2.Out", duration: .6, delay:-.3})           
    
    // fnish composition 1 --> break
    .set({}, {}, "+=3")
    .call(() => {
      showAC(tl, animationStateService);
    });


  // this.calcProgress()
}

export function buildPanel2(
                              tl: gsap.core.Timeline,  
                              animationStateService: AnimationStateService
                            ) {
  let panel2_X="-37%"
  if (animationStateService.mq_1496.matches){
    panel2_X="-13%"
  } else if(animationStateService.mq_1368.matches) {
    panel2_X="-22%"
  } else if (animationStateService.mq_1246.matches){
    panel2_X="-27%"
  } else if (animationStateService.mq_1098.matches){
    panel2_X="-32%"
  }

  tl
      .call(() => {
        animationStateService.currentAnimationStart = tl.time()
  
      })     
      .to(".panel1", {x:"-100%", opacity:0,ease: "power2.Out", duration: .4, delay:0, display:"none"})
      .to(".whiteboard", {x:"87%", y:"4rem",ease: "power2.Out", duration: 1, delay:0})      
      .fromTo(".showcase-wrapper", {scale:.5,x:"-78%", y:"15%"},{scale:.5, x:"-39%", y:"25%",duration:.6, delay:-.6})
      .to(".student", {x:"4rem",y:"-24rem", scale:1.2, opacity:1, duration: .6, delay:0, display:"block"})


      .fromTo(".panel2", {x:panel2_X,  opacity:0, display:"block"}, {x:panel2_X,opacity:1,ease: "power2.Out", duration: .8, delay:0}) 
      .fromTo(".panel2-content-subhead", {opacity:0, display:"block"}, {opacity:1,ease: "power2.Out", duration: .8, delay:-.3})           
      .fromTo(".panel2-content-text", {x: "0rem",  opacity:0, display:"block"}, {x:"12rem", opacity:1,ease: "power2.Out", duration: .8, delay:1})

      .call(() => {
        showAC(tl, animationStateService);
      })

      // fnish composition 2 --> break
      .set({}, {}, "+=2")
    
}

export function buildPanel3(
                              tl: gsap.core.Timeline,  
                              animationStateService: AnimationStateService
                            ) {
  tl    
    .call(() => {
      animationStateService.previousAnimationStart = animationStateService.currentAnimationStart
      animationStateService.currentAnimationStart = tl.time()

    })   
    .to(".panel2", {opacity:0,ease: "power2.Out", duration: .4, delay:-1, display:"none"})
    .to(".whiteboard", {opacity:0,ease: "power2.Out", duration: 1, delay:-1, display:"none"})      
    .to(".showcase-wrapper", {opacity:0,ease: "power2.Out", duration: 1, delay:-1, display:"none"})  
    .to(".student", {opacity:0,ease: "power2.Out", duration: 1, delay:-1, display:"none"})      


    .fromTo(".panel3", {x: "-50%",  opacity:0, display:"block"}, {x:"-50%", opacity:1,ease: "power2.inOut", duration: 2, delay:1}) 
    .fromTo(".panel3-content-subhead", {x: "0%",  opacity:0, display:"block"}, {x:"0%", opacity:1,ease: "power2.inOut", duration: 1.2, delay:1}) 
    .fromTo(".panel3-content-subhead2", {x: "-3%",  opacity:0, display:"block"}, {x:"0%", opacity:1,ease: "power2.inOut", duration: 1.2, delay:1})   

    .to(".goggle", {x: "0%", y: "2rem", opacity: 0, display: "flex",scale:.9, duration: 0, delay:-1.2})
    .to(".goggle", {x: "0%", y: "2rem", scale:1, opacity:1,ease: "power2.inOut", duration: 1, delay:-1})
    .fromTo(".panel3-content-subtext", {opacity:0, x:"5rem"},{opacity:1,x:"2rem",ease: "power2.Out", duration: .6, delay:1, fontSize:"1.5rem",display:"flex"})

    // finish composition 3 
    .set({}, {}, "+=3")
    .call(() => {
      showAC(tl, animationStateService);
  });
}


export function buildPanel4(
                              tl: gsap.core.Timeline,  
                              animationStateService: AnimationStateService
                            ) {
  tl
    .call(() => {
      animationStateService.previousAnimationStart = animationStateService.currentAnimationStart
      animationStateService.currentAnimationStart = tl.time()
    }) 
    .to(".panel3", {x:"-100%", opacity:0,ease: "power2.Out", duration: .8, delay:0})
    .to(".goggle", {x: "-61%", y: "11rem", scale:.8, opacity:1,ease: "power2.inOut", duration: .8, delay:-.8})
    .to(".showcase-wrapper", {x:"-68%",y: 0, opacity:0, ease: "power2.inOut", duration: .6, delay:-.5})
    .to(".showcase-wrapper", {y: "9rem",scale:.45,duration: 0, delay:0})
    .to(".whiteboard", {x: "-420%", y: "-8rem",scale:1.3, duration: 0, delay:0}) 

    .fromTo(".panel4", {x: "-100%",  opacity:0, display:"block"}, {x:0, opacity:1,ease: "power2.inOut", duration: .8, delay:-.3}) 
    .fromTo(".panel4-content-subhead", {x: "-8%",  opacity:0, display:"block"}, {x:0, opacity:1,ease: "power2.inOut", duration: .6, delay:-.1})           
    .fromTo(".rent-vr", {x: "150%", opacity:0}, {x:"17rem", opacity:1, display:"flex", ease: "power2.inOut", duration: .8, delay:0, onComplete: () => {
      gsap.set(".ib-rent", {className: "info-boxes ib-rent ib-rent-active"})
    }})

    // fnish wait for panel4-content-subtext  --> break
    .set({}, {}, "+=3")
    .fromTo(".panel4-content-subtext", {opacity:0},{opacity:1,ease: "power2.Out", duration: .6, delay:.7, display:"flex"})
    .fromTo(".panel4-content-subtext2", {opacity:0},{opacity:1,ease: "power2.Out", duration: .6, delay:.5, display:"flex"})
    .fromTo(".panel4-content-subtext3", {opacity:0},{opacity:1,ease: "power2.Out", duration: .6, delay:.6, display:"flex"})
    .fromTo(".panel4-content-subtext4", {opacity:0},{opacity:1,ease: "power2.Out", duration: .5, delay:.6, display:"flex"})
    

    .to(".panel4-content-subtext2", {y:"2rem", x:"1.5rem", duration:.6, opacity:0, delay:1})
    .to(".panel4-content-subtext4", {y:"-2rem", x:".5rem",duration:.6, opacity:0, delay:-.6})
    .to(".panel4-content-subtext3", {x:"-1.5rem",duration:.6, opacity:0, delay: -.6, scale:.75, transformOrigin: '20% 50%'})
    
    .fromTo(".panel4-emerge", {y:"-7.5rem",opacity:0, display:"block", scale:.5, transformOrigin: '25% 50%'}, {y:"-7.5rem",opacity:1 ,duration:.6, delay:0, scale:1})
    
    //finish Panel 4
    .set({}, {}, "+=3")
    .call(() => {
      showAC(tl, animationStateService);
    });
}

export function buildPanel5(
                              tl: gsap.core.Timeline,  
                              animationStateService: AnimationStateService,
                              renderer:Renderer2
                            ) {
  tl
    // fadeout 4
    .call(() => {
      animationStateService.previousAnimationStart = animationStateService.currentAnimationStart
      animationStateService.currentAnimationStart = tl.time()
    }) 
    .to(".panel4-content-subtext3", {opacity:0})
    .to(".panel4", {x: "-100%",  opacity:0, display:"none",ease: "power2.inOut", duration: .6}) 
    .to(".panel4-content-subtext2", {y:"-2rem", x:"-1rem", duration:0, delay:0})
    .to(".panel4-content-subtext4", {y:"2rem", x:"-.5rem",duration:0, delay:0})
    .to(".rent-vr", {x: "100%", opacity:0, ease: "power2.inOut", duration: .6, delay:-.1})
    .to(".goggle", {x: "-105%", y:"15rem",ease: "power2.inOut", duration: .8, delay:0})
    .fromTo(".panel5", {x: "-100%",  opacity:0, display:"block"}, {x:0, opacity:1,ease: "power2.inOut", duration: .8, delay:-.3}) 
    .fromTo(".panel5-content-subhead", {x:0, y:0, opacity:0},{opacity:1,ease: "power2.Out", duration: .6, delay:2, display:"flex",onComplete: () => startTraining(renderer)})
    .fromTo(".vr-dome", {opacity:0, x:"28rem", y:"7rem", scale:.75, display:"flex"},{x:"0rem",y:"7rem",opacity:1, ease: "power2.Out", duration: 1, delay:0})
    
    .fromTo(".panel5-content-subtext", {x:"7rem", y:"2rem",opacity:0},{x:"0rem",y:"2rem", opacity:1,ease: "power2.Out", duration: .6, delay:2})
    
    // fnish composition 5 --> break
    .set({}, {}, "+=26")
    .call(() => {
      // your function here
      showAC(tl, animationStateService);
    });
}



export function buildPanel6(
                              tl: gsap.core.Timeline,  
                              animationStateService: AnimationStateService
                            ) {
  tl
    .call(() => {
      animationStateService.previousAnimationStart = animationStateService.currentAnimationStart
      animationStateService.currentAnimationStart = tl.time()
      animationStateService.animationControllerPanel.nativeElement.style.opacity=0
    }) 
    .to(".vr-dome", {opacity:0,duration: .6, display:'none'})
    .to(".goggle", {opacity:0,ease: "power2.inOut", duration: .8, delay:-.8})
    .to(".panel5-content-subhead", {opacity:0,ease: "power2.inOut", duration: .8, delay:-.8})
    .to(".panel5-content-subtext", {opacity:0,ease: "power2.inOut", duration: .8, delay:-.8})
    .to(".panel5", {ease: "power2.inOut",opacity:0, duration: .8, delay:-.8})
    .to(".header-formular", {opacity:0, duration: .8, delay:0})
    .to(".rent-vr", {display:"none", delay:0})
    .set({}, {}, "+=1") 

    .fromTo(".panel6-logo", {x: "-5rem", y:"0rem", opacity:0, display:"block"}, {x:"14rem", y:"0rem", opacity:1,ease: "power2.inOut", duration: .8, delay:-.6}) 
    
    .fromTo(".conc-laptop", {x:"-75rem" ,y: "-16.5rem",scale:.9, opacity:0, display:"flex"}, {x:"-31em" ,y: "-16.5rem",scale:.9,duration: .6, delay:0, opacity:1})
    .fromTo(".panel6-logo-s1", {opacity:0, display:"block"}, {opacity:1,ease: "power2.inOut", duration: .6, delay:.2})   
    .fromTo(".panel6-logo-s2", {opacity:0, display:"block"}, {opacity:1,ease: "power2.inOut", duration: .6, delay:.2})   
    .fromTo(".panel6-logo-s3", {opacity:0, display:"block"}, {opacity:1,ease: "power2.inOut", duration: .6, delay:.2})   
    
    .fromTo(".panel6", {x: "20rem", y:"-88%", opacity:0, display:"block"}, {x:"-10rem", y:"-88%", opacity:1,ease: "power2.inOut", duration: 1, delay:-.6})
    .fromTo(".panel6-usp1", {x:"3rem",opacity:0, display:"block"}, {x:"0rem",opacity:1,ease: "power2.inOut", duration: 1, delay:.6})   
    .fromTo(".panel6-usp2", {x:"3rem",opacity:0, display:"block"}, {x:"0rem",opacity:1,ease: "power2.inOut", duration: 1, delay:.3})   
    .fromTo(".panel6-usp3", {x:"3rem",opacity:0, display:"block"}, {x:"0rem",opacity:1,ease: "power2.inOut", duration: 1, delay:.3})   
    
    .fromTo(".panel6-formular", {y:"-6rem",opacity:0, display:"block", scale:.8, transformOrigin: '50% 50%'}, {scale:1,y:"-11rem",opacity:1,ease: "power2.inOut", duration: .5, delay:2})
    .to(".panel6-formular", {scale:.8, duration: .2, delay:.2,ease: "power2.Out",transformOrigin: '50% 50%'})
    .to(".panel6-formular", {scale:1, duration: .1, delay:.2,ease: "power2.Out",transformOrigin: '50% 50%'})
    .to(".panel6-formular", {duration: 0, delay:.2,transformOrigin: '0% 50%'})

    .fromTo(".restart-tour", {x: "23rem",y:"-13rem",opacity:0, display:"block"}, {scale:1, x:"19rem",y:"-13rem",opacity:1,ease: "power2.inOut", duration: 1, delay:-.9})   
    .fromTo(".partners", {display:"none"}, {display:"block", duration: 1, delay:-.7}) 
    
    .call(() => {
      // your function here
      // tl.restart()
      location.reload();
    });


}







//--------------------------------------------------------------------------------
function startVideo(renderer:Renderer2){
  const showcaseVideo = renderer.selectRootElement('#showcaseVideo');
  const training = renderer.selectRootElement('#vrTraining');
  training.pause()
  training.currentTime = 0;
  showcaseVideo.currentTime = 0; // starts the video at 2 seconds
  showcaseVideo.play();
  console.log('video started')
}

function startTraining(renderer:Renderer2){
  const training = renderer.selectRootElement('#vrTraining');
  setTimeout(() => {
      training.currentTime = 0; 
      training.play();
  }, 500);

}

function showAC(tl: gsap.core.Timeline, animationStateService: AnimationStateService) {
  console.log('show ac -> build up finished');
  animationStateService.buildUpFinished = true;
  if (animationStateService.playPauseToggle.nativeElement.innerText === 'play_circle') {
    showSkipButtons(animationStateService);
  }
  pauseTimelineIfPlaying(tl, animationStateService);
  calcProgress(tl, animationStateService);
}

function showSkipButtons(animationStateService: AnimationStateService) {
  animationStateService.imgSkipNext.nativeElement.style.display = 'block';
  animationStateService.imgSkipPrevious.nativeElement.style.display = 'block';
  animationStateService.imgSkipNext.nativeElement.style.opacity = '1';
  animationStateService.imgSkipPrevious.nativeElement.style.opacity = '1';
}

function pauseTimelineIfPlaying(tl: gsap.core.Timeline,animationStateService: AnimationStateService) {
  if (animationStateService.animationController) {
    tl.pause();
  }
}

function calcProgress(tl: gsap.core.Timeline,animationStateService: AnimationStateService){
  // this.circleProgress.nativeElement.style.opacity = 1
  const circleProgressEl = document.getElementById('circleProgress');
  if (circleProgressEl) {
    circleProgressEl.style.opacity = '1';
  }
  animationStateService.animationControllerPanel.nativeElement.style.pointerEvents = "all"
  const step = (raf: number) => {
    // Get the start time of the animation
    if (!animationStateService.animationStart) animationStateService.animationStart = raf;
    // Calculate the progress of the animation
    let progress = raf - animationStateService.animationStart!;
    animationStateService.animationDurationIndicator = (1 - Math.cos(progress / 5000 * (Math.PI / 2))) * 100;

    // Check if the animation is not yet complete
    if (animationStateService.animationDurationIndicator < 100) {
      // If not, request another animation frame
      requestAnimationFrame(step);
    } else {
      if (!animationStateService.animationshowStop){
        if (circleProgressEl) {
          circleProgressEl.style.opacity = '0';
        }
        // this.animationControllerPanel.nativeElement.style.pointerEvents = "none"
        tl.play()
        animationStateService.buildUpFinished = false
        animationStateService.animationStart = 0
        animationStateService.animationDurationIndicator=0
      }
    }
  };
  // Request the first animation frame
  requestAnimationFrame(step);
}