import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { logOutUser } from '../store/app.actions'
import { DataService } from '../services/data.services';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  data: any;
  constructor(
    private store: Store<{Auth: {loggedIn: boolean} }>,
    private dataService: DataService
    
  ) { }

  ngOnInit(): void {
  }

  showFormFromNav(){
    this.data = 'showFormFromNav'
    this.dataService.setData(this.data);
  }
}