import { logInUser, logOutUser } from "./app.actions"
import { Auth } from "./app.state"
import { createReducer, on } from "@ngrx/store"
 
const _appReducer = createReducer(
  Auth, 
  on(logInUser, (state) => {
    return {
      ...state,
      loggedIn: true,
    }
  }),
  on(logOutUser, (state) => {
    return {
      ...state,
      loggedIn: false,
    }
  })
)

export function appReducer(state:any, action:any) {
  return _appReducer(state, action)
}