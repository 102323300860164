import { Component, OnInit,ViewChild, ElementRef, Output, EventEmitter, Input, Renderer2, OnChanges,SimpleChange } from '@angular/core';
import { gsap } from "gsap";
import { Store } from '@ngrx/store';


@Component({
  selector: 'app-tour-guid',
  templateUrl: './tour-guid.component.html',
  styleUrls: ['./tour-guid.component.css']
})
export class TourGuidComponent implements OnInit {
  @Input() showTourPanel: boolean=true;
  @Output() toggleTour = new EventEmitter();
  @ViewChild('startAnimation') startAnimation?: ElementRef;
  @Input() ShowVideo?: ElementRef;
  @Input() restart?: boolean;
  @Input() _start_tour?: boolean;
  @Input() _showForm?: boolean;
  @Input() _closeForm?: boolean;

  animationController = true;

  clicked = false;
  tl = gsap.timeline();
  finishPanel1 = false;
  finishPanel2 = false;
  finishPanel3 = false;
  finishPanel4 = false;
  finishPanel5 = false;
  finishPanel6 = false;
  form_out = false;
  today!:string
  constructor(private renderer: Renderer2, private el: ElementRef) { }

  ngOnInit(): void {
    var dateToday = new Date();
    var dd = String(dateToday.getDate()).padStart(2, '0');
    var mm = String(dateToday.getMonth() + 1).padStart(2, '0'); //January is 0!
    var yyyy = dateToday.getFullYear();
    this.today = yyyy + '-' + mm + '-' + dd;
  }

  ngOnChanges(changes: {[propKey: string]: SimpleChange}) {
    if (changes.hasOwnProperty('restart')) {
      const restartChange = changes['restart'];
      if(restartChange.currentValue){
        this.restartTour();
      }
    }

    if (changes.hasOwnProperty('_showForm')) {
      const restartChange = changes['_showForm'];
      if(restartChange.currentValue){
        this.showForm()
      }
    }

    if (changes.hasOwnProperty('_closeForm')) {
      const restartChange = changes['_closeForm'];
      if(restartChange.currentValue){
        this.closeForm()
      }
    }

    if (changes.hasOwnProperty('_start_tour')) {
      const restartChange = changes['_start_tour'];
      if(restartChange.currentValue){
        this.showApp()
      }
    }
  }

  restartTour(){
    console.log('I run when restart is true');
    this.hideLastPanel()
    this.tl.restart()      
           
  
  }

  hideLastPanel(){
    this.tl
      .fromTo(".restart-tour", {x:"31rem",y:"-8rem",opacity:1,ease: "power2.inOut"},{x: "40rem",y:"-8rem",opacity:0, display:"none", duration: .6, delay:0})   
      .fromTo(".panel6-formular", {y:"-6rem",opacity:1,ease: "power2.inOut"}, {y:"3rem",opacity:0, display:"none", duration: .6, delay:-.4})   
      .fromTo(".panel6-logo", {x:"14rem", y:"1rem", opacity:1,ease: "power2.inOut"},{x: "-5rem", y:"1rem", opacity:0, display:"none", duration: .6, delay:-.6}) 
      .fromTo(".panel6", {x:"-10rem", y:"2rem", opacity:1,ease: "power2.inOut"}, {x: "20rem", y:"2rem", opacity:0, display:"none", duration: .6, delay:-.6}) 
      .fromTo(".conc-laptop", {x:"29rem",y:"-13rem",opacity:1,ease: "power2.inOut"},{x: "-76rem",y:"-13rem",opacity:0, display:"none", duration: .6, delay:-.6})   
      .fromTo(".showcase-wrapper", {x:"-12rem" ,y: "-5rem",scale:.45, opacity:1}, {x:"62rem" ,y: "-5rem",scale:.45, opacity:0,duration: .6, delay:-.6, display:"none", onComplete: () => {
        gsap.set(".show-video-active", {className: "show-video"})
        gsap.set(".front-vr-hidden", {className: "front-vr"})
        gsap.set(".conclusio-vr-active", {className: "conclusio-vr"})
        gsap.set(".ib-rent", {className: "info-boxes ib-rent"})
      }})
  }


  showForm(){
    let tl = gsap.timeline()
    tl
      // .fromTo(".panel6-formular", {opacity:1,ease: "power2.inOut"}, {opacity:0, display:"none", duration: .6, delay:-.6})   
      // .fromTo(".restart-tour", {opacity:1,ease: "power2.inOut"},{opacity:0, display:"none", duration: .6, delay:-.6})   
      // .fromTo(".panel6", {opacity:1,ease: "power2.inOut"}, {opacity:0, display:"none", duration: .6, delay:-.6}) 
      // .to(".panel3", {display:'none'})

      // .fromTo(".showcase-wrapper", {x:"-12rem" ,y: "-5rem",scale:.45, opacity:1}, {x:"62rem" ,y: "-5rem",scale:.45, opacity:0,duration: .6, delay:-.6, display:"none", onComplete: () => {
      //   gsap.set(".show-video-active", {className: "show-video"})
      //   gsap.set(".front-vr-hidden", {className: "front-vr"})
      //   gsap.set(".conclusio-vr-active", {className: "conclusio-vr"})
      //   gsap.set(".ib-rent", {className: "info-boxes ib-rent"})
      // }})
      
      // .set({}, {}, "+=1")
       
      .to(".contact-div", {display:'flex'})
      .to(".restart-tour", {opacity:0,ease: "power2.inOut", duration: .2})   
      .fromTo(".userForm", {y:"10rem", opacity: 0, display:"block"}, {y:"0rem", opacity: 1, ease: "power1.Out",duration: .6, onComplete: () => {
        let datefield = document.getElementById('date')as HTMLInputElement
        if (datefield) {
          datefield.setAttribute("min", this.today);
          datefield.value = this.today;
        }
     
      }})
      .to(".contact-form", {backgroundColor: "white", ease: "power1.Out",duration: .6, delay:-.6})
      .to(".close-formular", {x: "-5rem", display:'block'})
    }

  closeForm(){
    let tl = gsap.timeline()
    tl
    .to(".close-formular", {x: "0", display:'none'})
    .fromTo(".userForm", {y:0, opacity: 1}, {y:"10rem", opacity: 0, display:"block", ease: "power1.Out",duration: .6})
    .to(".contact-form", {backgroundColor: "transparent", ease: "power1.Out",duration: .6, delay:-.6})
    .to(".contact-div", {display:'none'})
    .to(".restart-tour", {opacity:1,ease: "power2.Out", duration: .2})   
  }


  hideTourPanel(){
    let tl = gsap.timeline()
    tl
      .fromTo(".tour-wrapper", {opacity: 1}, {opacity: 0, ease: "power1.Out",duration: .3})
      .to(".SC", {opacity:1, onComplete: this.startTour.bind(this)})
      this.showTourPanel = false
  }
  
  closeDialog(){
    if (this.clicked) {
      return;
    }
    this.clicked = true;
    //this.hideTourPanel()
     
    setTimeout(() => {
      this.toggleTour.emit();
    }, 500);

    
  }

  continuePanel2(){
    console.log('continue')
  }

  

  showTourStart(){
    // this.tl = gsap.timeline()
    //   .to(".whiteboard", {x: 1000, opacity:0})
    //   // .to(".whiteboard-video", {display: "block", opacity:0})
    //   .fromTo(".start-tour", {opacity:0, display:"none"}, {opacity: 1,ease: "power1.inOut", display:"block",duration: .4, delay:0})
    //   .fromTo(".show-video",{opacity:0}, {opacity: 1, ease: "power2.inOut", duration: .4, delay:0})

    //   .fromTo(".showcase-wrapper", {scale:1, opacity:0, display:"none"}, {x:"-50%",scale:1, opacity:1, display:"flex",duration: .8, delay:-.4})
    //   .to(".tour-wrapper", {display:'flex', scale:1.01, duration:.6, onComplete: () => {
    //     gsap.set(".show-video", {className: "show-video-active"})
    //     // this.startVideo()
    //   }})
    //   //.to('.app-wrapper', {display:"flex",opacity:1,onComplete: this.showApp.bind(this)})

    //   // , onComplete: () => {
    //   //   gsap.set(".show-video", {className: "show-video-active"})
    //   //   // this.startVideo()
    //   // }})
      
  }

  showApp(){
    this.tl
      .to(".show-video-active", {opacity:1})
      .to('.app-wrapper', {display:"flex",opacity:1})
      
      // , onComplete: () => {
      //   this.showPanel1.bind(this)

  }

  showPanel1(){
    this.finishPanel1= false
    this.tl
      //wait for app to finish
      .fromTo(".start-tour", {opacity:1}, {opacity:0, duration: .4, delay:5})
      .set({}, {}, "+=44") 
      .to(".showcase-wrapper", {x:"-14rem", y:"8rem",scale:.65, ease: "power2.inOut", duration: .4, delay:.5, onComplete: () => {
          gsap.set(".front-vr", {className: "front-vr-hidden"})
          gsap.set(".conclusio-vr", {className: "conclusio-vr-active"})
      }})
      //.to(".topic", {innerText: "2. Nutzen Sie die App in Kombination mit der Brille im Unterricht", opacity:0, duration: 0, delay: -.1})
      //.to(".topic", {innerText: "2. Nutzen Sie die App in Kombination mit der Brille im Unterricht", opacity:1, duration: .4, delay: .1
      .fromTo(".panel1", {x: "-100%",  opacity:0, display:"block"}, {x:"-83%", opacity:1,ease: "power2.inOut", duration: .8, delay:-.3}) 
      .fromTo(".panel-content-subhead", {x: "-10%",  opacity:0, display:"block"}, {x:0, opacity:1,ease: "power2.inOut", duration: .8, delay:-.3})           
      // .fromTo(".panel-content-text", {x: "-10%",  opacity:0, display:"block"}, {x:0, opacity:1,ease: "power2.inOut", duration: .8, delay:1})           
      .set({}, {}, "+=1")
      .to(".panel-content-text", {x:"-100%", opacity:0,ease: "power2.Out", duration: .8, delay:1, display:"none"})       

      // .to(".whiteboard-video", {opacity:1})
      .fromTo(".whiteboard", {x:"49rem", y:"3rem", scale:1.7, opacity:0, display:"block"}, {x:"95%", y:"3rem",scale:1.8, opacity:1,ease: "power2.Out", duration: 1, delay:-.9})   
      .fromTo(".panel-content-text2", {x: "20%",  opacity:0, display:"block"}, {x:0, opacity:1,ease: "power2.Out", duration: .4, delay:.1, onComplete: this.showAC1.bind(this)})           
      
      //.set({}, {}, "+=5")
  }



  showPanel2(){
    this.finishPanel1= false
    this.finishPanel2= false
    this.tl
      //wait for panel 1
      .set({}, {}, "+=2")
      .to(".student", {x:"3rem",y:"-2rem", opacity:0, duration: 0, delay:0, display:"none"})
      .to(".panel1", {x:"-100%", opacity:0,ease: "power2.Out", duration: .4, delay:0, display:"none"})
      .fromTo(".whiteboard", {x:"95%", y:"3rem",scale:1.8}, {x:"87%", y:"5rem",scale:1.6, opacity:1,ease: "power2.Out", duration: 1, delay:0})      
      .fromTo(".showcase-wrapper", {x:"-14rem", y:"8rem",scale:.65},{x:"10%", y:"24%",ease: "power2.inOut", scale:.5,duration: 1, delay:-.8})       

      .to(".student", {x:"1rem",y:"-39rem", opacity:1,ease: "power2.Out", duration: .8, delay:-.6, display:"block", scale:1.2})
      .fromTo(".panel2", {x: "-13%",  opacity:0, display:"block"}, {x:"-13%",opacity:1,ease: "power2.Out", duration: .8, delay:0}) 
      .fromTo(".panel2-content-subhead", {opacity:0, display:"block"}, {opacity:1,ease: "power2.Out", duration: .8, delay:-.3})           
      .fromTo(".panel2-content-text", {x: "0rem",  opacity:0, display:"block"}, {x:"12rem", opacity:1,ease: "power2.Out", duration: .8, delay:1,onComplete: this.showAC2.bind(this)})

      // .to(".student", {x:"-2rem",y:"-39rem", opacity:1,ease: "power2.Out", duration: .4, delay:1})
      // .fromTo(".student-front", {x: "0rem", y:"20rem", scale:0, opacity:0, display:"block"}, {x: "-25rem",y:"-27rem",scale:1.2, opacity:1, display:"block", ease: "power2.Out", duration: .6, delay:-.2,onComplete: this.showAC2.bind(this)})
      
  }

  showPanel3(){
    this.finishPanel2= false
    this.finishPanel3= false
    let fadeout_previous= .6
    this.tl
      //wait for panel 2
      .set({}, {}, "+=3")
      .to(".panel2", {opacity:0,ease: "power2.Out", duration: fadeout_previous, delay:-fadeout_previous, display:"none"})
      .to(".showcase-wrapper", {opacity:0, ease: "power2.Out", duration: fadeout_previous, delay:-fadeout_previous})       
      .to(".whiteboard", {opacity:0 ,ease: "power2.Out", duration: fadeout_previous, delay:-fadeout_previous})      
      .to(".student", {opacity:0,ease: "power2.Out", duration: fadeout_previous, delay:-fadeout_previous})
      // .to(".topic", {innerText: "3. Verleihen Sie die VR Brille an ihre Fahrschüler und verdienen Sie Geld", opacity:0, duration: 0, delay: -.1})
      // .to(".topic", {innerText: "3. Verleihen Sie die VR Brille an ihre Fahrschüler und verdienen Sie Geld", opacity:1, duration: .4, delay: .1})        
      .to(".ib-connection", {x:"100%",opacity:0, ease: "power2.inOut", duration: .6, delay:-.6})

      .fromTo(".panel3", {x: "-50%",  opacity:0, display:"block"}, {x:"-50%", opacity:1,ease: "power2.inOut", duration: 2, delay:1}) 
      .fromTo(".panel3-content-subhead", {x: "0%",  opacity:0, display:"block"}, {x:"0%", opacity:1,ease: "power2.inOut", duration: 1.2, delay:1})   
      .to(".goggle", {x: "0%", y: "6rem", opacity: 0, display: "flex",scale:1, duration: 0, delay:0})
      .to(".goggle", {x: "0%", y: "2rem", scale:1, opacity:1,ease: "power2.inOut", duration: 1, delay:.1})
      .fromTo(".panel3-content-subtext", {opacity:0, x:"13rem"},{opacity:1,x:"0rem",ease: "power2.Out", duration: .6, delay:.5, fontSize:"1.5rem",display:"flex",onComplete: this.showAC3.bind(this)})
  }


  showPanel4(){
    this.finishPanel3= false
    this.finishPanel4= false
    this.tl
      //wait for panel 1
      .set({}, {}, "+=3")
      .to(".panel3", {x:"-100%", opacity:0,ease: "power2.Out", duration: .8, delay:0})
      .to(".goggle", {x: "-61%", y: "11rem", scale:.8, opacity:1,ease: "power2.inOut", duration: .8, delay:-.8})
      .to(".showcase-wrapper", {x:"-68%",y: 0, opacity:0, ease: "power2.inOut", duration: .6, delay:-.5})
      .to(".showcase-wrapper", {y: "9rem",scale:.45,duration: 0, delay:0})
      .to(".whiteboard", {x: "-420%", y: "-8rem",scale:1.3, duration: 0, delay:0}) 
      
      
      .fromTo(".panel4", {x: "-100%",  opacity:0, display:"block"}, {x:0, opacity:1,ease: "power2.inOut", duration: .8, delay:-.3}) 
      .fromTo(".panel4-content-subhead", {x: "-10%",  opacity:0, display:"block"}, {x:0, opacity:1,ease: "power2.inOut", duration: .4, delay:-.3})           
      .fromTo(".rent-vr", {x: "100%", opacity:0}, {x:"17rem", opacity:1, display:"flex", ease: "power2.inOut", duration: .6, delay:.3, onComplete: () => {
        gsap.set(".ib-rent", {className: "info-boxes ib-rent ib-rent-active"})
      }})
      .fromTo(".panel4-content-subtext", {opacity:0},{opacity:1,ease: "power2.Out", duration: .6, delay:1, display:"flex"})
      .fromTo(".panel4-content-subtext2", {opacity:0},{opacity:1,ease: "power2.Out", duration: .6, delay:.5, display:"flex"})
      .fromTo(".panel4-content-subtext3", {opacity:0},{opacity:1,ease: "power2.Out", duration: .6, delay:.6, display:"flex"})
      .fromTo(".panel4-content-subtext4", {opacity:0},{opacity:1,ease: "power2.Out", duration: .5, delay:.6, display:"flex"})
      
      
      
      .to(".panel4-content-subtext2", {y:"2rem", x:"1rem", duration:.6, opacity:0})
      .to(".panel4-content-subtext4", {y:"-2rem", x:".5rem",duration:.6, opacity:0, delay:-.6})
      .to(".panel4-content-subtext3", {duration:.6, opacity:0})


      .fromTo(".panel4-emerge", {opacity:0, display:"block"}, {y:"-8rem",opacity:1 ,duration:.6, delay:1})
      .set({}, {}, "+3")
      .to(".panel4-content-subtext3", {opacity:0,onComplete: this.showAC4.bind(this)})
      
      // Bereitet optimal auf den realen Straßenverkehr und die praktische Prüfung vor.
      
    }

  showPanel5(){
    this.finishPanel4= false
    this.finishPanel5= false
    this.tl
      //wait for panel 4
      .set({}, {}, "+3")
      .to(".panel4", {x: "-100%",  opacity:0, display:"none",ease: "power2.inOut", duration: .6}) 
      .to(".panel4-content-subtext2", {y:"-2rem", x:"-1rem", duration:0, delay:0})
      .to(".panel4-content-subtext4", {y:"2rem", x:"-.5rem",duration:0, delay:0})
      .to(".rent-vr", {x: "100%", opacity:0, ease: "power2.inOut", duration: .6, delay:-.1})
      .to(".goggle", {x: "-105%", y:"15rem",ease: "power2.inOut", duration: .8, delay:0})
      .fromTo(".panel5", {x: "-100%",  opacity:0, display:"block"}, {x:0, opacity:1,ease: "power2.inOut", duration: .8, delay:-.3}) 
      .fromTo(".panel5-content-subhead", {x:0, y:0, opacity:0},{opacity:1,ease: "power2.Out", duration: .6, delay:2, display:"flex"})
      .fromTo(".vr-dome", {x:"45rem", y:"8rem",opacity:0},{x: "0rem",y:"8rem",opacity:1, ease: "power2.Out", duration: .6, delay:0, display:"flex", scale:.8,onComplete: this.startTraining.bind(this)})
      .fromTo(".panel5-content-subtext", {x:"30rem", y:"23rem",opacity:0},{x:"35rem",y:"23rem", opacity:1,ease: "power2.Out", duration: .6, delay:2, display:"flex",onComplete: this.showAC5.bind(this)})
  }

  showPanel6(){
    this.finishPanel5= false
    this.finishPanel6= false
    let timer
    if (this.form_out){
      timer = "+=0"
    } else {
      timer = "+=8"
    }
    this.tl
      //wait for panel 5
      
      .set({}, {}, timer)
      // panel5 fadeout
      .to(".vr-dome", {opacity:0,duration: .6})
      .to(".goggle", {opacity:0,ease: "power2.inOut", duration: .8, delay:-.8})
      .to(".panel5-content-subhead", {opacity:0,ease: "power2.inOut", duration: .8, delay:-.8})
      .to(".panel5-content-subtext", {opacity:0,ease: "power2.inOut", duration: .8, delay:-.8})
      .to(".panel5", {ease: "power2.inOut",opacity:0, duration: .8, delay:-.8})
      .set({}, {}, "+=1") 

      .fromTo(".panel6-logo", {x: "-5rem", y:"1rem", opacity:0, display:"block"}, {x:"14rem", y:"1rem", opacity:1,ease: "power2.inOut", duration: .8, delay:-.6}) 
      
      .fromTo(".conc-laptop", {x:"-75rem" ,y: "-13rem",scale:.9, opacity:0, display:"flex"}, {x:"-31em" ,y: "-13rem",scale:.9,duration: .6, delay:0, opacity:1})
      .fromTo(".panel6-logo-s1", {opacity:0, display:"block"}, {opacity:1,ease: "power2.inOut", duration: .6, delay:.2})   
      .fromTo(".panel6-logo-s2", {opacity:0, display:"block"}, {opacity:1,ease: "power2.inOut", duration: .6, delay:.2})   
      .fromTo(".panel6-logo-s3", {opacity:0, display:"block"}, {opacity:1,ease: "power2.inOut", duration: .6, delay:.2})   
      
      .fromTo(".panel6", {x: "20rem", y:"2rem", opacity:0, display:"block"}, {x:"-10rem", y:"2rem", opacity:1,ease: "power2.inOut", duration: 1, delay:-.6}) 
      .fromTo(".panel6-usp1", {x:"3rem",opacity:0, display:"block"}, {x:"0rem",opacity:1,ease: "power2.inOut", duration: 1, delay:.6})   
      .fromTo(".panel6-usp2", {x:"3rem",opacity:0, display:"block"}, {x:"0rem",opacity:1,ease: "power2.inOut", duration: 1, delay:.3})   
      .fromTo(".panel6-usp3", {x:"3rem",opacity:0, display:"block"}, {x:"0rem",opacity:1,ease: "power2.inOut", duration: 1, delay:.3})   
      
      .fromTo(".panel6-formular", {y:"-2rem",opacity:0, display:"block"}, {y:"-6rem",opacity:1,ease: "power2.inOut", duration: 1, delay:1.5})   
      .fromTo(".restart-tour", {x: "1rem",y:"-8rem",opacity:0, display:"block"}, {x:"-3rem",y:"-8rem",opacity:1,ease: "power2.inOut", duration: 1, delay:-.9})   
      


  }
  showAC1(){
    if (this.animationController){
      this.finishPanel1 = true;
    } else {
      this.showPanel2()
    }
  }

  showAC2(){
    if (this.animationController){
      this.finishPanel2 = true;
    } else {
      this.showPanel3()
    }
  }

  showAC3(){
    if (this.animationController){
      this.finishPanel3 = true;
    } else {
    this.showPanel4()
    }
  } 

  showAC4(){
    if (this.animationController){
      this.finishPanel4= true;
    } else {
      this.showPanel5()
    }
  }
  showAC5(){
    if (this.animationController){
      this.finishPanel5= true;
    } else {
      this.showPanel6()
    }
  }

  continueWithPanel2(){
    console.log('continue')
  }


  startTour(){
    console.log('start tour now!!')
    this.startAnimation?.nativeElement.setAttribute('disabled', 'disabled');

    this.tl.to(".showcase-wrapper", {opacity:0, display:"none"})
    this.showApp()
    
    // setTimeout(() => { 
    //   this.showApp()
    //   // this.showPanel2()
    //   // this.showPanel3()

    // }, 500);

  }

  startVideo(){
    const showcaseVideo = this.renderer.selectRootElement('#showcaseVideo');
    const training = this.renderer.selectRootElement('#vrTraining');
    training.pause()
    showcaseVideo.currentTime = 0; // starts the video at 2 seconds
    showcaseVideo.play();
    console.log('video started')
    // setTimeout(() => {
    //     showcaseVideo.pause();
    // }, 12000);

  }



  startTraining(){
    const training = this.renderer.selectRootElement('#vrTraining');
    setTimeout(() => {
        training.currentTime = 0; // starts the video at 2 seconds
        training.play();
    }, 1000);
 
  }


}

