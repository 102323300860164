
import { AnimationStateService } from '../../services/animationState.service';


//--------------------------------------------------------------------------------
// Animation Utils
//--------------------------------------------------------------------------------

export function showSkipButtons(animationStateService: AnimationStateService) {
  animationStateService.imgSkipNext.nativeElement.style.display = 'block';
  animationStateService.imgSkipPrevious.nativeElement.style.display = 'block';
  setTimeout(() => {
    animationStateService.imgSkipNext.nativeElement.style.opacity = '1';
    animationStateService.imgSkipPrevious.nativeElement.style.opacity = '1';
  }, 500);
}

export function hideSkipButtons(animationStateService: AnimationStateService) {
  animationStateService.imgSkipNext.nativeElement.style.opacity = '0';
  animationStateService.imgSkipPrevious.nativeElement.style.opacity = '0';
  setTimeout(() => {
    animationStateService.imgSkipNext.nativeElement.style.display = 'none';
    animationStateService.imgSkipPrevious.nativeElement.style.display = 'none';
  }, 500);
}

export function getPreviousPanel(tl: gsap.core.Timeline, animationStateService: AnimationStateService) {
  if (animationStateService.imgSkipPrevious.nativeElement.style.opacity == 1) {
    seekTimelineTo(tl, animationStateService.previousAnimationStart!);
    playTimeline(tl);
    hideSkipButtons(animationStateService);
  }
}

export function getNextPanel(tl: gsap.core.Timeline, animationStateService: AnimationStateService) {
  hideSkipButtons(animationStateService);
  if (animationStateService.buildUpFinished) {
    playTimeline(tl);
    animationStateService.buildUpFinished = false;
  }
}

export function togglePlayPause (tl: gsap.core.Timeline, animationStateService: AnimationStateService) {
  if (animationStateService.animationshowStop) {
    // Resume the animation
    animationStateService.animationshowStop = false;
    animationStateService.playPauseToggle.nativeElement.innerText = 'pause_circle';
    if (animationStateService.buildUpFinished) {
      tl.play();
      showSkipButtons(animationStateService);
      animationStateService.buildUpFinished = false;
    }
    hideSkipButtons(animationStateService);
  } else {
    // Pause the animation
    animationStateService.animationshowStop = true;
    animationStateService.playPauseToggle.nativeElement.innerText = 'play_circle';
    if (animationStateService.buildUpFinished) {
      tl.pause();
      showSkipButtons(animationStateService);
      animationStateService.buildUpFinished = false;
      animationStateService.animationStart = 0;
      animationStateService.animationDurationIndicator = 0;
    } else{
      hideSkipButtons(animationStateService);
      tl.play();
    }
  }
}







function playTimeline(tl: gsap.core.Timeline) {
  tl.play();
}

function seekTimelineTo(tl: gsap.core.Timeline, time: number) {
  tl.seek(time);
}