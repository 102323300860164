
<div #Intro id="IntroComponent" class="IntroComponent" (click)="playIntroVideo()">
  <div class="IntroBG">
    <div (click)="closeIntroComponent()" class="IntroClose" style="display: none;">
      <p>weiter</p>
      <p style="font-weight: 700; margin-left: .5rem;">X</p>
    </div>

    <div class="video-controls" style="display: none;">
      <i class="fas fa-regular fa-play"></i> 
    </div>

    <video 
      #IntroVideo 
      controls
      autoplay
      id="IntroVideo" 
      src="../assets/videos/Fahrschule360_Teaser_LOW.mp4" 
      width="100%" 
      height="100%"
      ></video>
  </div>
</div>
