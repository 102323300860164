import { Renderer2, Component, OnInit, ViewChild, ElementRef, AfterViewInit} from '@angular/core';
import { gsap } from "gsap";
import { Store } from '@ngrx/store';
import { logOutUser } from '../store/app.actions'
import { DataService, Data } from '../services/data.services';
import { AnimationStateService } from '../services/animationState.service';
import { Subscription } from 'rxjs';
import { showApp, buildPanel1, buildPanel2, buildPanel3, buildPanel4, buildPanel5, buildPanel6 } from './helper/panel-builder';
import { showSkipButtons, hideSkipButtons, getPreviousPanel, getNextPanel, togglePlayPause } from './helper/animation-utils';

@Component({
  selector: 'app-showcase',
  templateUrl: './showcase.component.html',
  styleUrls: ['./showcase.component.css']
})

export class ShowcaseComponent implements OnInit, AfterViewInit {
  @ViewChild('IntroVideo') IntroVideo!: ElementRef;
  @ViewChild('Intro') Intro!: ElementRef;
  @ViewChild('animationControllerPanel') animationControllerPanel!: ElementRef;
  @ViewChild('playPauseToggle') playPauseToggle!: ElementRef;
  @ViewChild('imgSkipPrevious') imgSkipPrevious!: ElementRef;
  @ViewChild('imgSkipNext') imgSkipNext!: ElementRef;
  
  tl = gsap.timeline();
  restart=false
  _start_tour=false
  _showForm=false
  _closeForm=false
  message?: any;
  subscription?: Subscription;

  constructor(
    private renderer: Renderer2,
    private store: Store<{Auth: {loggedIn: boolean} }>,
    private dataService: DataService,
    private animationStateService: AnimationStateService
  ) { }

  ngOnInit(): void {
    const button = document.getElementById('IntroVideo');
    button?.addEventListener('pause', function handleClick(event) {});
    button?.addEventListener('ended', event => {
      this.closeIntroComponent()
    })
    this.subscribeToFormDataFromNav()
  }

  ngAfterViewInit() {
    this.animationStateService.setPlayPauseToggle(this.playPauseToggle);
    this.animationStateService.setImgSkipNext(this.imgSkipNext);
    this.animationStateService.setImgSkipPrevious(this.imgSkipPrevious);
    this.animationStateService.setAnimationControllerPanel(this.animationControllerPanel);
  }

  //--------------------------------------------------------------------------------
  public getAnimationDurationIndicator(): number {
    return this.animationStateService.animationDurationIndicator;
  }
  public getAnimationShowProgress(): boolean {
    return this.animationStateService.animationshowStop;
  }
  public getAnimationController(): boolean {
    return this.animationStateService.animationController;
  }

  //--------------------------------------------------------------------------------
  // Panel Animation
  //--------------------------------------------------------------------------------
  closeIntroComponent(){
    this.closeIntro()
    showApp(this.tl, this.animationStateService, this.renderer)
    buildPanel1(this.tl, this.animationStateService)
    buildPanel2(this.tl, this.animationStateService)
    buildPanel3(this.tl, this.animationStateService)
    buildPanel4(this.tl, this.animationStateService)
    buildPanel5(this.tl, this.animationStateService, this.renderer)
    buildPanel6(this.tl, this.animationStateService)  
  }

  //--------------------------------------------------------------------------------
  // Animation Controls
  //--------------------------------------------------------------------------------
  showPrevious() {
    getPreviousPanel(this.tl, this.animationStateService);
  }
  showNext() {
    getNextPanel(this.tl, this.animationStateService)
  }
  showStop() { // Play/Pause toggle
    togglePlayPause(this.tl, this.animationStateService);
  }

  //--------------------------------------------------------------------------------
  subscribeToFormDataFromNav() {
    this.subscription = this.dataService.data.subscribe((data) => {
      this.message = data;
      if (this.message === "showFormFromNav") {
        const video = document.getElementById('IntroVideo') as HTMLVideoElement;
        
        if (video.paused) {         
          if (this.tl.paused()) {
            this.tl.play();
            this.animationControllerPanel.nativeElement.style.opacity = 0;
          } else {
            const videoContainer = document.getElementById('IntroComponent');
            
            if (videoContainer?.style.opacity) {
              this.formSeek();
            } else {
              this.closeIntroComponent();
              this.formSeek();
            }
          }
      
          this.formSeek();
        } else {
          this.closeIntroComponent();
          this.formSeek();
        }
      }
    });
  }

  closeIntroEL(){
    this._start_tour =true
    setTimeout(() => {
      this._start_tour=false
    }, 1000);
  }

  closeIntro(){
    let tl = gsap.timeline();
    tl
      .to(".IntroComponent", {ease: "power1.Out", opacity:0,display:"none", duration: .6})

    this.tl
      .fromTo(".showcase", {x: 1000, opacity:0, scale:1}, {x:0,opacity: 1, scale:1,ease: "power1.inOut", duration: .6, delay:-.4})

  }  

  formSeek() {
    this.tl.seek(this.tl.duration())
    setTimeout(() => {
      this.showForm()
    }, 500);
  }

  showForm(){
    this._showForm =true
    setTimeout(() => {
      this._showForm=false
    }, 1000);
  }

  closeForm(){
    this._closeForm =true
    setTimeout(() => {
      this._closeForm=false
    }, 1000);
  }

  restartTour(){
    this.tl.restart()
  }
  

  logoutUser(){
    localStorage.setItem('isLoggedIn', 'false');
    this.store.dispatch(logOutUser())
  }


}



