import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-conclusio',
  templateUrl: './conclusio.component.html',
  styleUrls: ['./conclusio.component.css']
})
export class ConclusioComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
