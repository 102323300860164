<div class="header" style="width: 100%; margin-bottom: 0rem;">
  <div class="header-wrapper">
    <!-- <a href="">
      <img src="../../assets/img/vvr-logo.jpeg" class="vvr-logo" alt="">
    </a> -->
    <p style="margin-top: 2rem; color:#00519D">Kontaktieren Sie uns gerne:<br> 
    Telefon: 06131 - 62 480 80 / E-Mail: info@fahrschule-360.de</p>

    <div class="header-formular" (click)="showFormFromNav()" style="text-align: center;z-index: 10;padding: .8rem; margin: .8rem;border-radius: 1rem; background-color: #009D15; display: flex;align-items: center;">
      <p style="cursor:pointer;color: #FFF;font-size: 1rem;padding: 0.4rem;padding-top: 0rem;padding-bottom: 0rem; margin-bottom: 0rem!important;">Jetzt mit einem Experten sprechen & <br><span style="text-decoration: underline;">Starterbonus sichern</span></p>
    </div>

    <img src="../../assets/img/logo.svg" class="f360-logo" alt="">
  </div>
  <div class="protonav">
    <p class="slogan">Ausbildung der nächsten Generation</p>
    <p class="topic"></p>
    
  </div>
</div>