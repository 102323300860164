// data.service.ts
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

export interface Data {
  message: string;
}

@Injectable({
  providedIn: 'root'
})
export class DataService {
  private dataSubject = new BehaviorSubject<Data>({ message: '' });
  data = this.dataSubject.asObservable();

  constructor() { }

  setData(data: Data) {
    console.log('set data: ', data)
    this.dataSubject.next(data);
  }
}