
<div class="contact-div" style="height: 50rem; overflow-y: scroll; display: none; scale: .6; transform: translateY(-17rem);">
  <form (ngSubmit)="onSubmit()" class="userForm" style="width: 72rem; text-align: left; display: none; height: 800px;">
    <div>
      <label for="name">Ihr Name:</label>
      <input type="text" id="name" [(ngModel)]="name" name="name" required>
    </div>
    <div>
      <label for="email">Ihre Mail-Addresse:</label>
      <input type="email" id="email" [(ngModel)]="email" name="email" required>
    </div>
    <div>
      <label for="callToggle">Ich wünsche einen Rückruf</label>
      <label class="switch">
        <input type="checkbox" [(ngModel)]="callToggle" name="callToggle">
        <span class="slider round"></span>
      </label>
    </div>
    <div *ngIf="callToggle">
        <label for="telephone">Telefon:</label>
        <input type="tel" id="telephone" [(ngModel)]="telephone" name="telephone">
    </div>
    <div *ngIf="callToggle">
      <div class="date-time-container">
        <div style="width: 100%;">
          <label for="date">Datum:</label>
          <input type="date" id="date" [(ngModel)]="date" name="date" >
        </div>
        <div style="width:100%">
          <label for="time">Uhrzeit:</label>
          <input type="time" id="time" [(ngModel)]="time" name="time">
        </div>
      </div>
    </div>
    <div>
      <label for="message">Nachricht:</label>
      <textarea id="message" [(ngModel)]="message" name="message" placeholder="Ich möchte weitere Informationen zum Produkt" required></textarea>
    </div>
    <button type="submit">Senden</button>
  </form>
</div>