<div class="tour-wrapper" style="width: 100%; height: 100%; display: none;">
  <div style="width: 100%; height: 100%;display: flex;justify-content: center;align-items: center;overflow: hidden">
    <img src="../../assets/img/loginScreen.png" alt="" style="width: 100%; height: 100%;flex-shrink: 0;">

  </div>

  <div class="welcome-dialog" style="position: absolute;left: 50%;top: 50%;transform: translate(-122%, -50%);background-color: white;width: 225px;height: 365px;padding: 0.5rem;border-radius: 0.25rem;">
    <img class="logo" src ="../../assets/img/logo.svg" alt="logo" width="80%" style="user-select: none;width: 9rem;margin-top: 0.5rem;">
    <p style="font-size: 1.5rem; text-align: center; margin-top: 1rem;user-select: none;">Klicken Sie auf Starten</p>
    <a #startAnimation id="startAnimation" (click)="closeDialog()" [ngClass]="{'disabled': clicked}" style="cursor: pointer;display: flex;justify-content: center;">
      <h5 mat-dialog-title class="mat-dialog-title startBtn" style="margin-top: 1rem;font-size: 1.5rem;user-select: none;border-radius: .25rem;padding: 1rem; padding-left: 2rem; padding-right: 2rem; background-color: #021824; color: #fff;">Tour starten</h5>
    </a>  

  </div>

  
</div>





<!-- <div *ngIf="finishPanel2"  class="animation-controller">
  <button (click)="showPanel2()">wiederholen</button>
  <button (click)="showPanel3()">nächstes</button>
</div>

<div *ngIf="finishPanel3"  class="animation-controller">
  <button (click)="showPanel3()">wiederholen</button>
  <button (click)="showPanel4()">nächstes</button>
</div>

<div *ngIf="finishPanel4"  class="animation-controller">
  <button (click)="showPanel4()">wiederholen</button>
  <button (click)="showPanel5()">nächstes</button>
</div>

<div *ngIf="finishPanel5"  class="animation-controller">
  <button (click)="showPanel5()">wiederholen</button>
  <button (click)="showPanel6()">nächstes</button>
</div> -->

