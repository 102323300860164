import { APP_INITIALIZER, NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppComponent } from './app.component';
import { TourGuidComponent } from '../app/tour-guid/tour-guid.component';
import { ShowcaseComponent } from './showcase/showcase.component';
import { VrGoggleComponent } from './vr-goggle/vr-goggle.component';
import { IntroComponent } from './intro/intro.component';
import { HeaderComponent } from './header/header.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { StoreModule } from '@ngrx/store';
import { appReducer } from '../app/store/app.reducer';
import { WhiteboardComponent } from './whiteboard/whiteboard.component';
import { VrDomeComponent } from './vr-dome/vr-dome.component';
import { ConclusioComponent } from './conclusio/conclusio.component';
import { RentVrComponent } from './rent-vr/rent-vr.component';
import { StudentComponent } from './student/student.component';
import { ContactFormComponent } from './contact-form/contact-form.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatButtonModule } from '@angular/material/button';
import { NgCircleProgressModule } from 'ng-circle-progress';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { ServiceWorkerService } from './services/serviceworker.service';


@NgModule({
  declarations: [
    AppComponent,
    ShowcaseComponent,
    VrGoggleComponent,
    IntroComponent,
    HeaderComponent,
    TourGuidComponent,
    WhiteboardComponent,
    VrDomeComponent,
    ConclusioComponent,
    RentVrComponent,
    StudentComponent,
    ContactFormComponent
  ],
  imports: [
    BrowserModule,
    MatButtonModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    StoreModule.forRoot({Auth: appReducer}),
    BrowserAnimationsModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
    NgCircleProgressModule.forRoot({
      // set defaults here
      radius: 100,
      outerStrokeWidth: 16,
      innerStrokeWidth: 8,
      outerStrokeColor: "#78C000",
      innerStrokeColor: "#C7E596",
      animationDuration: 300,
    })
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: (serviceWorker: ServiceWorkerService) => () => serviceWorker.initializeApp(),
      deps: [ServiceWorkerService],
      multi: true
    }
  ],
  bootstrap: [AppComponent],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ],
})
export class AppModule { }
