import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ServiceWorkerService {

  constructor() { }

  initializeApp() {
    return new Promise<void>((resolve, reject) => {
      if ('serviceWorker' in navigator) {
        navigator.serviceWorker.register('ngsw-worker.js').then(registration => {
          if (registration.active) {
            if (registration.active.state === 'activated') {
              console.log('Service worker is active and has finished fetching everything');
              // display a message to the user
              // document.getElementById('message').innerText = 'All content is available offline';
              resolve();
            } else {
              registration.active.addEventListener('statechange', () => {
                if (registration.active!.state === 'activated') {
                  console.log('Service worker is active and has finished fetching everything');
                  // display a message to the user
                  // document.getElementById('message').innerText = 'All content is available offline';
                  resolve();
                }
              });
            }
          }
        }).catch(error => reject(error));
      } else {
        reject('Service worker not supported');
      }
    });
  }
}
